<template>
  <form @submit.prevent="submit">
    <div class="row">
      <loading-notification :show="loading" />
    </div>
    <div class="row">
      <div class="flex xs12 md6">
        <team-bar
          :loading="loading"
          :disabled="disabled"
          :with-filters="teamFilter"
          :initial-team="initialTeam"
          @selected-team="teamSelected"
        />
      </div>
      <div class="flex xs12 md6">
        <va-select
          v-model="selectedLeader"
          :label="$t('layout.form.preaching_leaderInput')"
          :options="leadersList"
          :loading="isLoading.leaders"
          :disabled="loading"
          text-by="name"
          @update-search="asyncFindLeader"
          searchable
        />
      </div>
    </div>
    <div class="row">
      <location-bar
        :disabled="true"
        :loading="loading"
        :with-filters="['regions', 'countries', 'districts']"
        :initial-district="initialDistrict"
        @selected-district="districtSelected"
      />
    </div>
    <div class="row">
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.name.value"
          :disabled="loading"
          :label="$t('layout.form.nameInput')"
          :error="!!form.name.errors.length"
          :error-messages="translatedErrors(form.name.errors)"
          @blur="validate('name')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-date-picker
          id="date-input"
          :label="$t('layout.form.preaching_dateInput')"
          v-model="preachingDate"
        />
      </div>
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.code.value"
          :disabled="disabled || isNew"
          :label="$t('layout.form.preaching_codeInput')"
          :error="!!form.code.errors.length"
          :error-messages="translatedErrors(form.code.errors)"
          @blur="validate('code')"
        />
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-input
          color="info"
          v-model="form.address.value"
          type="textarea"
          :min-rows="5"
          :autosize="true"
          :disabled="loading"
          :label="$t('layout.form.addressInput')"
          :error="!!form.address.errors.length"
          :error-messages="translatedErrors(form.address.errors)"
          @blur="validate('address')"
        />
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <h3>{{ $t("preaching_points.attendance") }}</h3>
      </div>
    </div>

    <div class="row">
      <div class="flex xs12 md4">
        <va-input
          color="info"
          type="number"
          min="0"
          v-model="form.assistance_kids.value"
          :disabled="loading"
          :label="$t('layout.form.total_kidsInput')"
          :error="!!form.assistance_kids.errors.length"
          :error-messages="translatedErrors(form.assistance_kids.errors)"
          @blur="validate('assistance_kids')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-input
          color="info"
          type="number"
          min="0"
          v-model="form.assistance_youngs.value"
          :disabled="loading"
          :label="$t('layout.form.total_youthInput')"
          :error="!!form.assistance_youngs.errors.length"
          :error-messages="translatedErrors(form.assistance_youngs.errors)"
          @blur="validate('assistance_youngs')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-input
          color="info"
          type="number"
          min="0"
          v-model="form.assistance_adults.value"
          :disabled="loading"
          :label="$t('layout.form.total_adultsInput')"
          :error="!!form.assistance_adults.errors.length"
          :error-messages="translatedErrors(form.assistance_adults.errors)"
          @blur="validate('assistance_adults')"
        />
      </div>
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-select
          v-model="selectedStatus"
          :label="$t('layout.form.status')"
          :options="statusList"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          text-by="name"
          searchable
        />
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-button color="primary" :disabled="loading">
          <text-loading :loading="loading" icon="fa fa-save">
            {{ $t("layout.form.save") }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import { validatorMixin } from "@/services/validator";
import parseISO from "date-fns/parseISO";

const TeamBar = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/Bars/Team");
const UserBar = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/Bars/User");
const LocationBar = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/Bars/Location");

export default {
  name: "preachings-form",
  components: {
    TeamBar,
    UserBar,
    LocationBar,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    preaching: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        };
      },
    },
    disabled: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  mixins: [validatorMixin],
  data() {
    return {
      isError: {
        leaders: false,
      },
      isLoading: {
        leaders: false,
      },
      teamFilter: "",
      initialLeader: null,
      selectedLeader: null,
      initialTeam: null,
      selectedTeam: null,
      preachingDate: null,
      initialDistrict: null,
      leadersList: [],
      countryId: "",
      selectedStatus: "",
      form: {
        name: {
          value: "",
          validate: {
            required: true,
          },
          errors: [],
        },
        code: {
          value: "",
          validate: {
            required: true,
          },
          errors: [],
        },
        assistance_kids: {
          value: "",
          validate: {
            required: true,
            minValue: 0,
          },
          errors: [],
        },
        assistance_youngs: {
          value: "",
          validate: {
            required: true,
            minValue: 0,
          },
          errors: [],
        },
        assistance_adults: {
          value: "",
          validate: {
            required: true,
            minValue: 0,
          },
          errors: [],
        },
        address: {
          value: "",
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    };
  },
  computed: {
    ...mapGetters(["currentUser", "currentLocale"]),
    isNew() {
      return this.preaching.id === 0;
    },
    statusList() {
      return [
        { id: 0, name: this.$t("points.status.open"), value: 0 },
        { id: 1, name: this.$t("points.status.close"), value: 1 },
        { id: 2, name: this.$t("points.status.graduation"), value: 2 },
      ];
    },
  },
  watch: {
    preaching(val) {
      this.setPreaching(val);
    },
    preachingDate(val) {
      if (!this.disabled) {
        this.setFormValue("code", this.generatePreachingCode());
      }
      this.validate("code");
    },
    async currentLocale(val) {
      await this.$nextTick();
      this.validateAll();
    },
  },
  created() {
    this.initialData();
    this.$nextTick(() => {
      this.validateAll();
    });
  },
  methods: {
    async setPreaching(val) {
      this.setFormData(val);
      console.log(val);
      if (val.id) {
        await this.$nextTick();
        this.validateAll();
      }

      if (val.team_id) {
        const posible_leaders = await this.getPosibleLeaders(val.team_id);
        if (posible_leaders) {
          this.leadersList = posible_leaders;
        }
      }

      if (val.leader) {
        this.initialLeader = val.leader;
        this.selectedLeader = val.leader; // Set the initial leader
      }

      if (val.code) {
        this.setFormValue("code", val.code);
        this.initialTeam = val.team;
      }

      if (val.district) {
        this.selectedRegion = val.region;
        this.selectedCountry = val.country;
        this.selectedDistrict = val.district;
        this.initialDistrict = val.district;
      }

      if (val.planted_date) {
        this.preachingDate = val.planted_date;
      }

      if (Number.isInteger(val.status)) {
        const status = this.statusList.find((s) => s.value === val.status);
        this.selectedStatus = status || "";
      }
    },
    generatePreachingCode() {
      let code = "";
      if (this.selectedTeam) {
        code = this.selectedTeam.code;
      }

      let month = "";
      let year = "";
      if (this.preachingDate) {
        let date = this.preachingDate;
        if (typeof date === "string") {
          date = new Date(parseISO(date));
        }
        month = date.getMonth() + 1;
        year = date.getFullYear();
      }
      return `${code}${year}${month}`;
    },
    teamSelected(team) {
      this.selectedTeam = team;
      this.adjustTeam(team);
      this.initialData(team);
      if (!this.disabled) {
        this.setFormValue("code", this.generatePreachingCode());
      }
      this.validate("code");

      // Set the initial leader based on the selected team
      if (team.leader) {
        this.selectedLeader = team.leader;
      }
    },
    async adjustTeam(team) {
      if (!team.district) return;
      this.selectedDistrict = team.district;
      this.initialLeader = team.leader;
      this.selectedLeader = team.leader; // Set the initial leader
      this.selectedCountry = team.country;
      this.selectedRegion = team.region_id;
      this.initialDistrict = this.selectedDistrict;
      this.getPosibleLeaders(team.district_id);

      this.setFormData(this.report);

      await this.$nextTick();
      this.validateAll();
    },
    async districtSelected(district) {
      this.selectedDistrict = district;
      this.selectedCountry = district.country;
      this.selectedRegion = district.region_id;
      this.initialDistrict = this.selectedDistrict;

      this.setFormData(this.report);

      await this.$nextTick();
      this.validateAll();
    },

    async getPosibleLeaders(district_id, query = "") {
      this.isLoading.leaders = true;
      try {
        const response = await this.$http.get(
          `/users?q=${query}&sort=name&direction=asc&limit=50&district_id=${district_id}`
        );
        console.log(response.data.data);
        const users = response.data.data;
        this.leadersList = this.getPosibleLeadersList(users);

        this.isLoading.leaders = false;
      } catch (error) {
        this.isLoading.leaders = false;
        this.isError.leaders = true;
        console.error(error);
      }
    },

    async asyncFindLeader(query) {
      await this.getPosibleLeaders(this.selectedDistrict.id, query);
      if (this.isError.leaders) {
        this.asyncFindLeader(query);
      }
    },

    getPosibleLeadersList(users) {
      return users.map((user) => {
        return {
          id: user.id,
          name: user.name,
        };
      });
    },

    leaderSelected(leader) {
      this.selectedLeader = leader;
    },
    disSelected(dis) {},
    initialData(team) {
      if (this.preaching) {
        this.setPreaching(this.preaching);
      }
    },
    checkEmpty(field) {
      return field === undefined || field === null || field === "";
    },
    async submit() {
      this.validateAll();
      if (!this.formReady) return;

      const preaching = this.getFormData(this.preaching);
      if (this.selectedLeader) {
        preaching.leader_id = this.selectedLeader.id;
      }
      if (this.preachingDate) {
        preaching.planted_date = this.preachingDate;
      }
      if (this.selectedDistrict && this.selectedDistrict.country) {
        preaching.region_id = this.selectedDistrict.country.region_id;
      }
      if (this.selectedCountry && this.selectedCountry.id) {
        preaching.country_id = this.selectedCountry.id;
      }
      if (this.selectedDistrict) {
        preaching.district_id = this.selectedDistrict.id;
      }
      if (this.selectedTeam) {
        preaching.team_id = this.selectedTeam.id;
      }
      preaching.status = this.selectedStatus.value;
      delete preaching.leader;
      delete preaching.user;
      delete preaching.district;
      delete preaching.country;
      delete preaching.region;
      this.$emit("submit", preaching);
    },
  },
};
</script>
