<template>
  <div>
    <div class="row">
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>
    <div class="flex xs12">
      <va-collapse withBackground>
        <span slot="header">{{ $t('layout.filters.label') }}</span>
        <template slot="body">
          <filters
            :loading="loading"
            :actions="filterActions"
            @filter="filterData"
            @clear-filters="filterData(null)"
          />
        </template>
      </va-collapse>
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-card :title="$t('trainings.trainings.index')">
          <remote-table
            class="table-hover table-striped"
            :columns="tableFields"
            :data="tableData"
            :loading="loading"
            :search="searchOptions"
            :pagination="pagination"
            :params="serverParams"
            :queries="serverParams.queries"
            :sortField="sort.field"
            :sortType="sort.type"
            sort-field="end_date"
            sort-type="desc"
            crud-links="trainings"
            controller="DcpiTrainings"
            @update-data="updateData"
            @search-data="searchByName"
            @delete-item="tryDelete"
            @download-item="prepareDownload"
          />
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
const Filters = () => import(/* webpackPrefetch: true */ '@/components/extras/Filters')
const RemoteTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/RemoteTable')

export default {
  name: 'trainings-index',
  components: {
    Filters, RemoteTable,
  },
  data () {
    return {
      error: false,
      tableData: [],
      pagination: {},
      searchQuery: '',
      loading: false,
      searchOptions: {
        enabled: true,
        trigger: 'enter',
        placeholder: this.$t('tables.actions.search'),
      },
      tableActions: [],
      sort: {
        field: 'code',
        type: 'asc',
      },
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: 'code',
          type: 'asc',
        },
        queries: '',
        page: 1,
        perPage: 50,
      },
      filterActions: ['regions', 'countries', 'districts', 'dateSince', 'dateUntil'],
    }
  },
  computed: {
    tableFields () {
      return [
        {
          name: 'end_date',
          title: this.$t('tables.headings.end_date'),
          sortField: 'end_date',
        },
        {
          name: 'training_type.name',
          title: this.$t('tables.headings.training_type'),
          sortField: 'TrainingTypes.name',
        },
        {
          name: 'organizer.name',
          title: this.$t('tables.headings.organizer'),
          sortField: 'Organizer.name',
        },
        {
          name: 'trainers_number',
          title: this.$t('tables.headings.trainers_number'),
        },
        {
          name: 'participants_number',
          title: this.$t('tables.headings.participants_number'),
        },
        {
          name: '__slot:actions',
          visible: this.tableData.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    filterData (filters) {
      if (!filters) {
        this.serverParams.queries = ''
        return
      }

      let queries = ''
      if (filters.region) {
        queries += '&region=' + filters.region
      }
      if (filters.country) {
        queries += '&country=' + filters.country
      }
      if (filters.district) {
        queries += '&district=' + filters.district
      }
      if (filters.dateSince) {
        queries += '&date_since=' + filters.dateSince
      }
      if (filters.dateUntil) {
        queries += '&date_until=' + filters.dateUntil
      }

      this.serverParams.queries = queries
    },
    fixData (data) {
      // let noDef = this.$t('tables.undefined')
      for (const d of data) {
        if (!d.organizer) {
          d.organizer = {
            name: '',
          }
        }
        if (!d.submitter) {
          d.submitter = {
            name: '',
          }
        }
        if (!d.training_type) {
          d.training_type = {
            name: '',
          }
        }
      }
      for (const training of data) {
        training.training_type.name = this.$t(training.training_type.name) || training.training_type.name
      }
      return data
    },
    apiUrl (params) {
      let route = 'trainings/dcpi'
      route += '?page=' + params.page || 0
      route += '&limit=' + params.perPage || 50
      if (params.sort && params.sort.field !== '') {
        let field = params.sort.field
        switch (field) {
          case 'submitter.name':
            field = 'Submitter.name'
            break
          case 'organizer.name':
            field = 'Organizer.name'
            break
        }
        route += '&sort=' + field
        route += '&direction=' + params.sort.type

        if (params.queries) {
          route += params.queries
        }
      }

      return route
    },
    async updateData (params) {
      params = params || this.serverParams
      this.loading = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(params))
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.error = true
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async searchByName (name) {
      if (name === '') return this.updateData()

      let response = false
      try {
        response = await this.$http.get('trainings/dcpi?q=' + name)
      } catch (err) {
        // console.log('Error searching training', err)
        this.loading = false
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      this.loading = true
      try {
        await this.$http.delete('trainings/dcpi/' + item.id)
      } catch (err) {
        // console.log('Error deleting training', err)
        this.loading = false
        return
      }
      this.updateData()
    },
    prepareDownload (format) {
      const prepareRoute = 'trainings/dcpi/download'
      const downloadRoute = 'trainings/dcpi/download/'

      return this.downloadFile(format, prepareRoute, downloadRoute)
    },
    async downloadFile (filters, prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.post(prepareRoute, filters)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
