<template>
  <div class="flex xs12">
    <local-table
      class="table-hover table-striped"
      :editable="edit"
      :columns="trainersFields"
      :data="trainersList"
      :loading="loading"
      :top-options="[]"
      :action-options="['delete']"
      @delete-item="tryDelete"
    >
      <template v-slot:top>
        <div class="flex xs12 md2 offset--md1">
          <va-button
            flat
            small
            v-if="edit"
            color="primary"
            icon="fa fa-plus"
            @click.prevent="tryAdd"
          >
            {{ $t('tables.actions.add') }}
          </va-button>
        </div>
        <div class="flex xs12 md1">
          <va-popover
            v-if="edit && !wizard"
            :message="$t('tables.actions.save')"
            placement="left"
          >
            <va-button
              flat
              small
              color="secondary"
              icon="fa fa-save"
              @click.prevent="submit"
            />
          </va-popover>
        </div>
      </template>
    </local-table>
    <va-modal
      v-if="edit"
      size="large"
      v-model="editModal"
      cancel-class="none"
      :title="$t('trainings.trainings.modal.trainers_title')"
      :ok-text="$t('modal.confirm')"
      :cancel-text="$t('modal.cancel')"
      :ok-disabled="haveErrors || isLoading"
      @ok="addUser"
      @cancel="trainer = ''"
    >
      <div class="row">
        <error-notification :show="haveErrors">
          <span>{{ $t('trainings.trainings.modal.existent_trainer') }}</span>
        </error-notification>
        <div class="flex xs12">
          <va-select
            v-model="trainer"
            text-by="label"
            :options="trainers"
            :loading="loading || isLoading"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            @update-search="findTrainers"
            searchable
          />
        </div>
      </div>
    </va-modal>
  </div>
</template>
<script>
const LocalTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/LocalTable')

export default {
  name: 'trainers-table',
  components: {
    LocalTable,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    wizard: {
      type: Boolean,
      default: false,
    },
    training: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      trainers: [],
      trainersList: [],
      trainersToDelete: [],
      trainer: '',
      editModal: false,
      isLoading: false,
      error: {
        existent: false,
        leader: false,
        invalid: false,
      },
    }
  },
  computed: {
    notValid () {
      return this.trainersList.length === 0
    },
    haveErrors () {
      return this.trainersList.some(t => t && t.id === this.trainer.id)
    },
    trainersFields () {
      return [
        {
          name: 'name',
          title: this.$t('tables.headings.name'),
        },
        {
          name: 'email',
          title: this.$t('tables.headings.email'),
        },
        {
          name: 'dcpi_role.name',
          title: this.$t('tables.headings.dcpi_role'),
        },
        {
          name: '__slot:actions',
          visible: !this.edit || this.trainersList.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  watch: {
    training (val) {
      if (val.trainers && val.trainers.length > 0) {
        const trainers = this.fixData(val.trainers)
        this.trainersList = trainers.slice(0)
      }

      if (this.edit) {
        this.findTrainers()
      }
    },
  },
  methods: {
    fixData (data) {
      const d = data.map(p => p.user || p)
      const noDef = this.$t('tables.undefined')

      for (const p of d) {
        if (p === undefined) continue
        const label = `${p.name} - ${p.email}`
        p.label = label

        if (p.dcpi_role === null) {
          p.dcpi_role = {
            name: noDef,
          }
        }
      }
      // translate dcpi_role
      d.forEach(p => {
        p.dcpi_role.name = this.$t(p.dcpi_role.name)
      })
      return d
    },
    filterData (data) {
      const d = data.filter(m => this.trainersList.findIndex(u => u.id === m.id) === -1)
      return this.fixData(d)
    },
    async findTrainers (search = '') {
      this.isLoading = true
      const queries = ['sort=name', 'direction=asc', 'trainer=true']
      if (search.length > 0) {
        queries.push('q=' + search)
      }

      const query = queries.join('&')
      const route = `/users?${query}`
      let response = false
      try {
        response = await this.$http.get(route)
      } catch (e) {
        // console.log('Error', e)
        this.isLoading = false
        return
      }

      const trainers = this.filterData(response.data.data) || []
      this.trainers = this.fixData(trainers)
      this.isLoading = false
    },
    checkResult (user) {
      this.error = {
        existent: false,
        leader: false,
        invalid: false,
      }

      const existent = this.trainersList.find(m => m.id === user.id)
      if (existent !== undefined) {
        this.error.existent = true
      }

      this.trainer = user
    },
    tryAdd () {
      this.editModal = true
    },
    addUser () {
      if (this.haveErrors) return

      const noDef = this.$t('tables.undefined')
      const trainer = Object.assign({}, this.trainer)
      if (trainer.dcpi_role === null) {
        trainer.dcpi_role = {
          name: noDef,
        }
      }

      this.trainersList.push(trainer)
      this.trainers = this.filterData(this.trainers)
      this.trainer = ''

      const noDeleteTrainer = this.trainersToDelete.findIndex(p => p.trainer_id === trainer.id)
      if (noDeleteTrainer !== -1) {
        this.trainersToDelete.splice(noDeleteTrainer, 1)
      }
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      this.trainersList = this.trainersList.filter(m => m.id !== item.id)
      this.trainersToDelete.push({ trainer_id: item.id })
    },
    submit () {
      const training = {
        id: this.training.id,
        users: {
          to_add: [],
          to_delete: this.trainersToDelete.map(t => t.trainer_id),
        },
      }
      const list = this.training.trainers ? this.trainersList.filter(m => {
        return this.training.trainers.find(t => t.trainer_id === m.id) === undefined
      }) : this.trainersList.slice(0)

      list.forEach(m => {
        training.users.to_add.push({ trainer_id: m.id })
      })

      this.$emit('submit', training)
    },
  },
}
</script>
