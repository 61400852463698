<template>
  <div class="flex xs12">
    <local-table
      class="table-hover table-striped"
      :editable="edit"
      :columns="participantsFields"
      :data="participantsList"
      :loading="loading"
      :top-options="[]"
    >
      <template v-slot:top>
        <div class="flex xs12 md2">
          <va-button
            flat
            small
            v-if="edit"
            color="primary"
            icon="fa fa-plus"
            @click.prevent="tryAdd"
          >
            {{ $t("tables.actions.add") }}
          </va-button>
        </div>
        <div class="flex xs12 md1">
          <va-popover
            v-if="resend && !wizard"
            :message="$t('tables.actions.download_certificates')"
            placement="left"
          >
            <va-button
              flat
              small
              color="success"
              icon="fa fa-download"
              @click.prevent="trySendAll(true)"
            />
          </va-popover>
        </div>
        <div class="flex xs12 md1">
          <va-popover
            v-if="resend && !wizard"
            :message="$t('tables.actions.resend_certificates')"
            placement="left"
          >
            <va-button
              flat
              small
              color="secondary"
              icon="fa fa-envelope-o"
              @click.prevent="trySendAll(false)"
            />
          </va-popover>
        </div>
        <div class="flex xs12 md1">
          <va-popover
            v-if="edit && !wizard"
            :message="$t('tables.actions.save')"
            placement="left"
          >
            <va-button
              flat
              small
              color="secondary"
              icon="fa fa-save"
              @click.prevent="submit"
            />
          </va-popover>
        </div>
      </template>
      <template v-slot:row-actions="{ props }">
        <va-popover
          v-if="resend && !wizard"
          :message="$t('tables.actions.download_certificate')"
          placement="left"
        >
          <va-button
            flat
            small
            color="success"
            icon="fa fa-download"
            @click.prevent="trySend(props.rowData, true)"
          />
        </va-popover>
        <va-popover
          v-if="resend && !wizard"
          :message="$t('tables.actions.send_certificate')"
          placement="left"
        >
          <va-button
            flat
            small
            color="primary"
            icon="fa fa-envelope-open-o"
            @click.prevent="trySend(props.rowData)"
          />
        </va-popover>
        <va-popover
          v-if="edit"
          :message="$t('tables.actions.change')"
          placement="left"
        >
          <va-button
            flat
            small
            color="primary"
            icon="fa fa-refresh"
            @click.prevent="tryChange(props.rowData)"
          />
        </va-popover>
        <va-popover
          v-if="edit"
          :message="$t('tables.actions.delete')"
          placement="left"
        >
          <va-button
            flat
            small
            color="danger"
            icon="fa fa-trash"
            @click.prevent="tryDelete(props.rowData)"
          />
        </va-popover>
      </template>
    </local-table>
    <va-modal
      v-if="edit"
      size="large"
      v-model="editModal"
      cancel-class="none"
      :title="$t('trainings.trainings.modal.participants_title')"
      :ok-text="$t('modal.confirm')"
      :cancel-text="$t('modal.cancel')"
      :ok-disabled="haveErrors || isLoading"
      @ok="addUser"
      @cancel="participant = ''"
    >
      <div class="row">
        <error-notification :show="haveErrors">
          <span>{{
            $t("trainings.trainings.modal.existent_participant")
          }}</span>
        </error-notification>
        <div class="flex xs12">
          <va-select
            v-model="participant"
            text-by="label"
            :options="participants"
            :loading="isLoading"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            @update-search="findAllUsers"
            searchable
          />
        </div>
      </div>
    </va-modal>
  </div>
</template>
<script
  type="text/javascript"
  src="http://ajax.googleapis.com/ajax/libs/jquery/1.8.3/jquery.min.js"
></script>
<script src="html2pdf.bundle.min.js"></script>
<script>
const LocalTable = () =>
  import(
    /* webpackPrefetch: true */ "@/components/extras/DataTables/LocalTable"
  );

export default {
  name: "participants-table",
  components: {
    LocalTable,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    resend: {
      type: Boolean,
      default: false,
    },
    wizard: {
      type: Boolean,
      default: false,
    },
    training: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    country: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      participants: [],
      participantsList: [],
      participantsToDelete: [],
      participant: "",
      editModal: false,
      isLoading: false,
      approvee: true,
    };
  },
  computed: {
    notValid() {
      return this.participantsList.length === 0;
    },
    haveErrors() {
      return this.participantsList.some(
        (t) => t && t.id_user === this.participant.id
      );
    },
    participantsFields() {
      return [
        {
          name: "name",
          title: this.$t("tables.headings.name"),
        },
        {
          name: "email",
          title: this.$t("tables.headings.email"),
        },
        {
          name: "dcpi_role.name",
          title: this.$t("tables.headings.dcpi_role"),
        },
        {
          name: "approve",
          title: this.$t("tables.headings.status"),
        },
        {
          name: "__slot:actions",
          visible:
            (this.edit || this.resend) && this.participantsList.length > 0,
          dataClass: "text-right",
          width: "20%",
        },
      ];
    },
  },
  watch: {
    training(val) {
      if (val.participants && val.participants.length > 0) {
        const participants = this.fixData(val.participants, false);
        this.participantsList = participants.slice(0);
      }

      if (this.edit) {
        this.findAllUsers();
      }
    },
    country(val) {
      this.findAllUsers();
    },
  },
  methods: {
    fixData(participants, isApproved) {
      const approvedLabel = this.$t("status.passed") || "status.passed";
      const reprobateLabel = this.$t("status.reprobate") || "status.reprobate";

      participants.forEach((participant) => {
        console.log(participant.user.dcpi_role.name);
        if (participant.user.dcpi_role.name) {
          participant.user.dcpi_role.name =
            this.$t(participant.user.dcpi_role.name) ||
            participant.user.dcpi_role.name;
        }
      });

      if (isApproved) {
        const users = participants.map(
          (participant) => participant.user || participant
        );
        const undefinedLabel = this.$t("tables.undefined");

        for (const user of users) {
          if (user === undefined) continue;

          const label = `${user.name} - ${user.email}`;
          user.label = label;

          if (user.dcpi_role === null) {
            user.dcpi_role = {
              name: undefinedLabel,
            };
          }
          user.id_user = user.id;
          user.approve = approvedLabel;
          this.approvee = true;
        }
        return users;
      }

      const users = participants.map(
        (participant) => participant || participant
      );
      const undefinedLabel = this.$t("tables.undefined");

      for (const participant of users) {
        if (participant === undefined) continue;

        const label = `${participant.user.name} - ${participant.user.email}`;
        participant.label = label;

        participant.name = participant.user.name;
        participant.email = participant.user.email;
        participant.id_user = participant.user.id;

        participant.dcpi_role = participant.user.dcpi_role;

        if (participant.user.dcpi_role === null) {
          participant.dcpi_role = {
            name: undefinedLabel,
          };
        }

        if (participant.approve == 1) {
          participant.approve = approvedLabel;
          this.approvee = true;
        } else {
          participant.approve = reprobateLabel;
          this.approvee = false;
        }
      }
      return users;
    },
    filterData(data) {
      const d = data.filter(
        (m) => this.participantsList.findIndex((u) => u.user_id === m.id) === -1
      );
      return this.fixData(d, true);
    },
    async findAllUsers(query) {
      this.isLoading = true;

      let route = "/users";
      let queries = ["with_role=true"];
      /*
      if (this.country) {
        queries.push(`country=${this.country.id}`
      }
      */
      if (query) {
        queries.push(`q=${query}`);
      }

      queries = queries.join("&");
      route = route + "?" + queries;
      let response = false;
      try {
        response = await this.$http.get(route);
      } catch (e) {
        // console.log('Error', e)
        this.isLoading = false;
        return;
      }

      const participants = this.filterData(response.data.data) || [];
      this.participants = this.fixData(participants, true);
      this.isLoading = false;
    },
    checkResult(user) {
      this.error = {
        existent: false,
        leader: false,
        invalid: false,
      };

      const existent = this.participantsList.find((m) => m.id === user.id);
      if (existent !== undefined) {
        this.error.existent = true;
      }

      this.participant = user;
    },
    tryAdd() {
      this.editModal = true;
    },
    addUser() {
      if (this.haveErrors) return;

      const noDef = this.$t("tables.undefined");
      const participant = Object.assign({}, this.participant);
      if (participant.dcpi_role === null) {
        participant.dcpi_role = {
          name: noDef,
        };
      }

      this.participantsList.push(participant);
      this.participants = this.filterData(this.participants);
      this.participant = "";

      const noDeleteParticipant = this.participantsToDelete.findIndex(
        (p) => p.participant_id === participant.id_user
      );
      if (noDeleteParticipant !== -1) {
        this.participantsToDelete.splice(noDeleteParticipant, 1);
      }
    },
    async tryDelete(item) {
      const result = await this.$swal({
        icon: "warning",
        text: this.$t("notifications.confirm.delete"),
        showCancelButton: true,
        confirmButtonText: this.$t("layout.buttons.confirm"),
        cancelButtonText: this.$t("layout.buttons.cancel"),
      });
      if (result.value !== true) return;

      this.participantsList = this.participantsList.filter(
        (m) => m.id !== item.id
      );
      this.participantsToDelete.push({ participant_id: item.id });
    },
    async tryChange(item) {
      let A = item.approve == "passed" ? "reprobate" : "passed";
      item.approve = A;
      const success = await this.par(item);
      console.log(item);
    },
    async par(participants) {
      const id = { id: participants.id };
      const t = {
        id: participants.id,
        participant_id: participants.participant_id,
        dcpi_training_id: participants.dcpi_training_id,
        approve: participants.approve == "passed" ? 1 : 0,
      };
      const success = await this.submite(t);
    },
    routeBuilder(id) {
      return `trainings/dcpi/updateParticipants/${id}`;
    },
    async submite(training) {
      console.log(training);
      const t = Object.assign({}, training);
      try {
        await this.$http.post(this.routeBuilder(t.id), t);
      } catch (error) {
        console.log("Error updating data", error);
        this.loading = false;
        return false;
      }
      return true;
    },
    async trySendAll(downloadCertificate) {
      const participants = this.participantsList
        .filter((p) => p.approve !== "reprobate")
        .slice(0);
      const count = participants.length;
      let passed = 0;
      let p = participants.shift();
      const downloadModal = (trainingId, userId, userEmail, userName) => ({
        icon: "info",
        title: `${this.$t(
          "notifications.resend.sending"
        )} (${passed}/${count})`,
        text: userEmail,
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading();
          const resendRoute = `trainings/dcpi/certificate/${trainingId}?user=${userId}&download=${
            downloadCertificate || "false"
          }`;
          try {
            const options = downloadCertificate
              ? { responseType: "blob" }
              : null;
            const response = await this.$http.get(resendRoute, options);
            if (downloadCertificate) {
              this.downloadFile(response.data, userName);
            }
          } catch (e) {
            // console.log('Cant resend file', e)
            const result = await this.$swal.queue([retry]);
            if (result.dismiss === "cancel") {
              const download = downloadModal(
                this.training.id,
                p.id_user,
                p.email,
                p.name
              );
              this.$swal.queue([download]);
            }
            return;
          }

          this.showToast(this.$t("notifications.alerts.success"), {
            icon: "fa-check",
            position: "top-right",
          });
          if (participants.length > 0) {
            p = participants.shift();
            passed++;
            const download = downloadModal(
              this.training.id,
              p.id_user,
              p.email,
              p.name
            );
            this.$swal.queue([download]);
          } else {
            this.$swal.queue([finish]);
          }
        },
      });
      const retry = {
        icon: "error",
        title: "Error",
        showCancelButton: true,
        text: this.$t("notifications.resend.failed"),
        confirmButtonText: this.$t("layout.continue"),
        cancelButtonText: this.$t("layout.retry"),
        allowOutsideClick: false,
        preConfirm: () => {
          p = participants.shift();
          const download = downloadModal(
            this.training.id,
            p.id,
            p.email,
            p.name
          );
          this.$swal.queue([download]);
        },
      };
      const finish = {
        icon: "success",
        title: this.$t(),
        text: this.$t("notifications.resend.success"),
        confirmButtonText: this.$t("layout.continue"),
        allowOutsideClick: true,
      };

      const download = downloadModal(this.training.id, p.id, p.email, p.name);
      this.$swal.queue([download]);
    },
    async trySend(item, downloadCertificate) {
      if (item.approve == "reprobate") {
        const result = await this.$swal({
          icon: "warning",
          text: this.$t(
            "notifications.resend.success.did.not.pass.the.training"
          ),
          showCancelButton: false,
          confirmButtonText: this.$t("layout.buttons.accept"),
        });
        return;
      }

      const download = {
        icon: "info",
        title: this.$t("notifications.resend.sending"),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading();
          const resendRoute = `trainings/dcpi/certificate/${
            this.training.id
          }?user=${item.id_user}&download=${downloadCertificate || "false"}`;
          try {
            const options = downloadCertificate
              ? { responseType: "blob" }
              : null;
            const response = await this.$http.get(resendRoute, options);
            if (downloadCertificate) {
              this.downloadFile(response.data, item.name);
            }
          } catch (e) {
            // console.log('Cant resend file', e)
            await this.$swal.queue([retry]);
            return;
          }

          this.showToast(this.$t("notifications.alerts.success"), {
            icon: "fa-check",
            position: "top-right",
          });
          this.$swal.close();
        },
      };
      const retry = {
        icon: "error",
        title: "Error",
        text: this.$t("notifications.resend.failed"),
        confirmButtonText: this.$t("layout.retry"),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download);
        },
      };
      this.$swal.queue([download]);
    },
    submit() {
      const training = {
        id: this.training.id,
        users: {
          to_add: [],
          to_delete: this.participantsToDelete.map((p) => p.participant_id),
        },
      };
      const list = this.training.participants
        ? this.participantsList.filter((m) => {
            return (
              this.training.participants.find(
                (t) => t.participant_id === m.id_user
              ) === undefined
            );
          })
        : this.participantsList.slice(0);

      list.forEach((m) => {
        training.users.to_add.push({
          participant_id: m.id_user,
          approve: m.approve == "Aprobado" ? 1 : 0,
        });
      });

      this.$emit("submit", training);
    },
    downloadFile(data, name) {
      /*       var printWindow = window.open('', '', 'height=400,width=800');
      printWindow.document.write('<html><head><title>Html to PDF</title>');
      printWindow.document.write('</head><body >');
      printWindow.document.write('<img src="data:image/svg;base64',blob,'>');
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      console.log(printWindow)
      printWindow.print(); */

      /* const blob = new Blob([data], { type: 'image/jpeg' }) */
      const blob = new Blob([data], { type: "application/pdf" });
      const a = document.createElement("a");
      document.body.appendChild(a);

      const url = window.URL.createObjectURL(data);
      a.href = url;
      const filename = name.replace(/\s+/, "_");
      /* a.download = `${filename}.jpeg` */
      a.download = `${filename}.pdf`;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    },
  },
};
</script>
